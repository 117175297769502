<template>
  <div>
    <div class="pop-conts">
      <div class="contents_wrap k-desc">
        <ul class="k-sea k-chec">
          <li class="w100 pd0 box_flex">
            <input
                ref="userNmRef"
                class="k-textbox w85"
                placeholder="임직원명을 입력하세요"
                @keyup.enter="commonOnChangeEvent"
            />
              <button @click="commonOnChangeEvent" class="mid_btn orange mgl10" >
                <span class="k-icon k-i-zoom"></span>검색
              </button>
            </li>
        </ul>
      </div>
      <!-- Kendo 영역 -->
      <kendo-grid
          id="grid"
          ref="gridRef"
          style="cursor: pointer;"
          :height="353"
          :navigatable="false"
          :pageable-page-sizes="[5, 10, 20, 50, 100, 500, 1000]"
          :reorderable="true"
          :column-menu="false"
          :resizable="true"
          :sortable-mode="'multiple'"
          :selectable="selectable"
          :data-source="userDataSource"
          :columns="gridColumns"
          :persistSelection="selectable !=='row'"
          :data-bound="gridDataBind"
          @change="onChangeGrid"
          :noRecords="{template:'<div class=\'gridNoData\' style=\'text-align: center;\'>표시할 데이터가 없습니다.</div>',}"
      >
      </kendo-grid>
      <!-- Kendo 영역 끝 -->
    </div>
    <div v-if="selectable !== 'row'" class="contents_wrap">
      <ul class="pro_w_list">
        <li v-for="unqUserId in computedSelectedUserCdList">
          {{ selectedMap[unqUserId].userNm }}<span @click="removeUserCd(unqUserId)" class="k-icon k-clear-value k-i-close"></span>
        </li>
      </ul>
    </div>
    <div class="button_area w60">
      <ul>
<!--        kendo-window가 아닌 곳에서 사용하게 되는경우 취소버튼 재정의를 하기위한 slot-->
        <slot :name="'closeButton'">
          <li><button @click="$parent.kendoWidget().close()" class="large_btn">취소</button></li>
        </slot>
        <li>
          <button class="large_btn orange ml5" @click="onClickSendUser">확인</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ApiConfig from '@/api/api.config'
import EtnersEfsUtil from '/src/common/etners.efs.util'
import { mapGetters } from 'vuex'

export default {
  name: "SearchUser",
  props : {
    selectable: {
      type: String,
      required: false,
      default : 'row',
      validator: function(value) {
        if(value){
          return ['row','multiple row'].indexOf(value) !== -1
        }
      }
    },
    existKey : {
      type: Array,
      required: false,
      default : function (){
        return []
      },
    },
    searchAuthCdList : {
      type: Array,
      required: false,
      default : function (){
        return []
      },
      validator: function(value) {
        let result = true
        if(value.length > 0){
          value.forEach(item=>{
            if(['05','10','15','25','35','99999'].indexOf(item) === -1) result = false
          })
        }
        return result
      }
    },
    searchCmpyCd : {
      type: String,
      required: false,
      default : '',
    },
    notSearchCmpyCd : {
      type: String,
      required: false,
      default : '',
    },
  },
  computed : {
    userDataSource: function () {
      const vm = this

      const gridDataSource = new kendo.data.DataSource({
        name: 'gridDataSource',
        page: 1,
        pageSize: 10,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
          read: {
            url: `${ApiConfig.efsDomain}/efs/manage/user`,
            type: 'GET',
            contentType: 'application/json',
            cache: false,
          },
          parameterMap: function (options, operation) {

            if (operation === 'read') {

              let filters = vm.searchFilterParam()
              let sort = EtnersEfsUtil.convertJSONArrayToJSON('sort', options.sort)

              return {
                loginUserToken: vm.$store.state.loginStore.lutk,
                skip: options.skip,
                take: options.take,
                page: options.page,
                pageSize: options.pageSize,
                searchAuthCdList : vm.$props.searchAuthCdList,
                searchCmpyCd : vm.$props.searchCmpyCd,
                notSearchCmpyCd : vm.$props.notSearchCmpyCd,
                ...sort,
                ...filters
              }
            }

            if (operation !== 'read' && options.models) {
              return { models: kendo.stringify(options.models) }
            }
          },
        },
        schema: {
          type: 'json',
          model: { id: 'unqUserId'},
          parse: function (response) {
            let changeResultData = response.resultData
            let rowNumber = (vm.$refs.gridRef.dataSource.page() - 1)
                * vm.$refs.gridRef.dataSource.pageSize()
            for (let i = 0; i < changeResultData?.length; i++) {
              let row = changeResultData[i]
              row.rowNumber = response.total - rowNumber++
            }
            response.resultData = changeResultData
            return response
          },
          total: function (response) {
            return response.total
          },
          data: function (response) {
            if (response.resultStatus.messageCode === '2000') {
              return response.resultData
            }else if(response.resultStatus.messageCode === '4154'){
              vm.$store.dispatch('LOGOUT').then(() => {
                kendo.alert(response.resultStatus.messageText).bind('close', function() {
                  window.location.href = `/login`
                })
              })
            }else {
              kendo.alert( response.resultStatus.messageText)
              return ''
            }
          },
        },
        error: function (e) {
          console.log('error event handler', e.errors[0])
        },
      })
      return gridDataSource
    },
    computedSelectedUserCdList : function (){
      let result = this.selectedUserCdList.sort()
      if(!!result){
        return result
      }else {
        return []
      }
    },
    ...mapGetters(['loginUserData'])
  },
  created () {
    const vm = this
    vm.$props.existKey.forEach((item, i)=>{
      vm.selectedMap[item.unqUserId] = item
      vm.selectedUserCdList.push(item.unqUserId)
      vm.propsKey.push(item.unqUserId)
    })
  },
  mounted () {
    this.$nextTick(e=>{
      /**
      * 여러개의 row를 선택하는 경우 체크박스가 아닌 영역을 눌러도 이전 선택 rows가 유지되며
      * 이미 선택된 row를 다시 click하는 경우 해당 row만 취소되도록 하는 코드.
      * (kendo native components의 기본 기능이지만 wrapper의 경우 아래와같이 커스텀이 필요함)
      * kendo-solution-link : https://docs.telerik.com/kendo-ui/knowledge-base/select-or-deselect-item-on-row-click#environment
      *  */
      kendo.jQuery("#grid tbody").on("click", "tr", function(e) {
        let rowElement = this;
        let row = kendo.jQuery(rowElement);
        let grid = kendo.jQuery("#grid").getKendoGrid();

        if (row.hasClass("k-state-selected")) {
          let selected = grid.select();
          selected = kendo.jQuery.grep(selected,function(x){
            let itemToRemove = grid.dataItem(row);
            let currentItem = grid.dataItem(x);

            return itemToRemove.unqUserId !== currentItem.unqUserId
          })
          grid.clearSelection();
          grid.select(selected);
          e.stopPropagation();
        }else{
          grid.select(row)
          e.stopPropagation();
        }
      });
    })
  },
  methods: {
    /**
    *  Array[object]인 객체에서 unqUserId 값만 담긴 Array 리턴한다.
    * */
    convertGetUserCdArray(array){
      let convertUserCd = []
      array.forEach((item, i)=>{
        convertUserCd.push(item.unqUserId)
      })
      return convertUserCd
    },
    /**
     *  하단에 선택된 unqUnqUserId에서 삭제를 하는 경우
     * */
    removeUserCd(unqUserId){
      const vm = this
      let gridDataSource = vm.$refs.gridRef.kendoWidget().dataSource._pristineData;

      /**
       * 현재 grid 목록에서 보이지 않는 경우
       * delUnqUserIdList에 저장(그리드데이터가 바인딩될 때 체크 해제)
       * */
      if(!vm.convertGetUserCdArray(gridDataSource).includes(unqUserId)){
        vm.delUnqUserIdList.push(unqUserId)
        vm.selectedUserCdList.forEach((item, i)=>{
          if(item === unqUserId){
            vm.selectedUserCdList.splice(i, 1)
          }
        })
      }
      /**
       * 현재 grid 목록에서 보이는 경우
       * grid click 이벤트 발생시켜서 목록해서 체크 해제
       * */
      else {
        vm.selectedUserCdList.forEach((item, i)=>{
          if(item === unqUserId){
            vm.selectedUserCdList.splice(i, 1)
            kendo.jQuery(`#grid tbody span#${item}`).parent().prevAll().find("input").trigger("click")
          }
        })
      }
    },
    gridDataBind(e){
      const vm = this
      let gridDataSource = vm.$refs.gridRef.kendoWidget().dataSource._pristineData;
      let completedRemove = []

      /**
       * 기존에 있던 key들이 현재 grid row에 있는경우 체크되어야하므로 click 이벤트 발생
       * */
      vm.propsKey = vm.propsKey.reduce((newData, item) => {
        if (vm.convertGetUserCdArray(gridDataSource).includes(item)) {
          kendo.jQuery(`#grid tbody span#${item}`).parent().prevAll().find("input").trigger("click")
        } else {
          newData.push(item)
        }
        return newData
      }, [])

      vm.delUnqUserIdList.forEach((item, i)=>{
        /**
         * 현재 grid 목록에서 삭제되었던 userId가 있는 경우
         * grid click 이벤트 발생시켜서 목록해서 체크 해제
         * */
        if(vm.convertGetUserCdArray(gridDataSource).includes(item)){
          kendo.jQuery(`#grid tbody span#${item}`).parent().prevAll().find("input").trigger("click")
          completedRemove.push(item)
        }
      })
      vm.delUnqUserIdList = vm.delUnqUserIdList.filter(x => !completedRemove.includes(x));

    },
    onChangeGrid (ev) {
      const vm = this
      let selectedRow = ev.sender.select()
      let rowData = ev.sender.dataItem(selectedRow)
      /**
       * selectedRows : 현재 보여지는 grid에서 선택된 row들의 목록
       * selectedKeyNames : kendo 자체에서 체크된 id 값 + 기존에 선택되어 있던 key Array
       * */
      let selectedRows = vm.$refs.gridRef.kendoWidget().select();
      let selectedKeyNames = vm.$refs.gridRef.kendoWidget().selectedKeyNames()
      vm.propsKey.forEach(item=>{
        if(!selectedKeyNames.includes(item)) selectedKeyNames.push(item)
      })
      vm.selectedUserCdList = selectedKeyNames.filter(x => !vm.delUnqUserIdList.includes(x));

      for (let i = 0; i < selectedRows.length; i++) {
        let rowData = vm.$refs.gridRef.kendoWidget().dataItem(selectedRows[i]);
        vm.selectedMap[rowData.unqUserId] = rowData
      }
    },
    onClickSendUser: function (e) {
      const vm = this;
      let result = []

      if(vm.selectable !== 'row'){
        vm.selectedUserCdList.forEach(item=>{
          result.push(vm.selectedMap[item])
        })
      }else {
        let selectedRow = vm.$refs.gridRef.kendoWidget().select()
        let rowData = vm.$refs.gridRef.kendoWidget().dataItem(selectedRow)
        result.push(rowData)
      }

      this.$parent.$emit('onPushUser',result);
      this.$parent.kendoWidget().close();
    },
    commonOnChangeEvent: function (e) {
      const gridRef = this.$refs.gridRef
      gridRef?.kendoWidget()?.dataSource?.query({
        sort: {},
        take: 10,
        page: 1,
        pageSize: 10,
      })
    },
    searchFilterParam: function () {
      let searchFilterOptions = {}
      const refs = this.$refs

      //회사명
      const searchUserNm = refs.userNmRef.value
      if (!!searchUserNm) {searchFilterOptions.searchUserNm = searchUserNm}

      return searchFilterOptions ?? undefined
    },
  },
  data: function () {
    const headerAttributes = {
      style: 'font-weight: bold; text-align: center;',
    }
    const centerAttributes = { style: 'text-align: center;' }

    return {
      selectedUserCdList : [],
      delUnqUserIdList : [],
      searchUseYn : 'Y',
      // selectedMap : 한번이라도 선택된 적이 있는 goods 정보
      selectedMap : {},
      propsKey : [],
      gridColumns: [
        {
          selectable: "true",
          width: "10%",
          attributes: { style: "text-align:center" },
          hidden: this.$props.selectable==='row'
        },
        {
          field: "cmpyNm",
          title: "회사명",
          width: "25%",
          headerAttributes: headerAttributes,
        },
        {
          field: "userNm",
          title: "성명",
          width: "20%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
        {
          field: "userId",
          title: "ID",
          width: "25%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
          template: function (dataItem) {
            return `<span id=${dataItem.unqUserId}>${dataItem.userId}`
          },
        },
        {
          field: "spTelNo",
          title: "연락처",
          width: "20%",
          headerAttributes: headerAttributes,
          attributes: centerAttributes,
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
